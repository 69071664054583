import * as ACTION_TYPES from './subcodeActionTypes';

export const subcodeReducerInitialState = {
	subcodeProcessInProgress: false,
	subcodeList: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	subcodeToEdit: undefined,
	subcodeDetailsPageData: undefined,
	selectedLegalInstrument: undefined,
	subcodeDetails: {
		subcodeId: undefined,
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	subcodeData: {
		subcodeId: undefined,
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	subcodeDataFile: {
		subcodeId: undefined,
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	postSubcode: {
		data: undefined,
		response: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	subcodeAffectations: {
		subcodeId: undefined,
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	subcodeAffectationsHistory: {
		subcodeId: undefined,
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	postCompensation: {
		responseData: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	preloadState: {
		data: false
	}
};

export const subcodeReducer = ( state=subcodeReducerInitialState, action ) => {
	switch( action.type ) {

		// Subcode process in progress
		case ACTION_TYPES.SET_SUBCODE_PROCESS_IN_PROGRESS: {
			const { value } = action.payload;
			return {
				...state,
				subcodeProcessInProgress: value
			};
		}
		// END Subcode process in progress


		// Subcode list actions
		case ACTION_TYPES.LIST_SUBCODE_REQUEST: {
			return {
				...state,
				subcodeList: {
					...state.subcodeList,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.LIST_SUBCODE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			const previousRecords = state.subcodeList.data?.data;

			if( previousRecords ) {
				data.data = previousRecords.concat(data.data);
			}
			return {
				...state,
				subcodeList: {
					...state.subcodeList,
					isFetching: false,
					hasError: false,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_SUBCODE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				subcodeList: {
					...state.subcodeList,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_SUBCODE_LIST_DATA: {
			return {
				...state,
				subcodeList: {
					...subcodeReducerInitialState.subcodeList
				}
			};
		}
		// END Subcode list actions



		// Subcode to edit actions
		case ACTION_TYPES.SET_SUBCODE_TO_EDIT: {
			const { data } = action.payload;
			return {
				...state,
				subcodeToEdit: data
			};
		}
		case ACTION_TYPES.CLEAR_SUBCODE_TO_EDIT: {
			return {
				...state,
				subcodeToEdit: subcodeReducerInitialState.subcodeToEdit
			};
		}
		// END Subcode to edit actions



		// Subcode details data
		case ACTION_TYPES.SET_SUBCODE_DETAILS_PAGE_DATA: {
			const { data } = action.payload;
			return {
				...state,
				subcodeDetailsPageData: data
			};
		}
		case ACTION_TYPES.CLEAR_SUBCODE_DETAILS_PAGE_DATA: {
			return {
				...state,
				subcodeDetailsPageData: subcodeReducerInitialState.subcodeDetailsPageData
			};
		}
		// END Subcode details data



		// Selected legal instrument actions
		case ACTION_TYPES.SET_SELECTED_LEGAL_INSTRUMENT: {
			const { data } = action.payload;
			return {
				...state,
				selectedLegalInstrument: data
			};
		}
		case ACTION_TYPES.CLEAR_SELECTED_LEGAL_INSTRUMENT: {
			return {
				...state,
				selectedLegalInstrument: subcodeReducerInitialState.selectedLegalInstrument
			};
		}
		// END Selected legal instrument actions



		// Subcode details actions
		case ACTION_TYPES.GET_SUBCODE_DETAILS_REQUEST: {
			const { subcodeId } = action.payload;
			return {
				...state,
				subcodeDetails: {
					...state.subcodeDetails,
					isFetching: true,
					hasError: false,
					error: subcodeReducerInitialState.subcodeDetails.error,
					data: subcodeReducerInitialState.subcodeDetails.data,
					subcodeId
				}
			};
		}
		case ACTION_TYPES.GET_SUBCODE_DETAILS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				subcodeDetails: {
					...state.subcodeDetails,
					isFetching: false,
					hasError: false,
					data
				}
			};
		}
		case ACTION_TYPES.GET_SUBCODE_DETAILS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				subcodeDetails: {
					...state.subcodeDetails,
					isFetching: false,
					hasError: true,
					data: subcodeReducerInitialState.subcodeDetails.data,
					subcodeId: subcodeReducerInitialState.subcodeDetails.subcodeId,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_SUBCODE_DETAILS: {
			return {
				...state,
				subcodeDetails: {
					...subcodeReducerInitialState.subcodeDetails
				}
			};
		}
		// END Subcode details actions



		// Subcode data actions
		case ACTION_TYPES.GET_SUBCODE_DATA_REQUEST: {
			const { subcodeId } = action.payload;
			return {
				...state,
				subcodeData: {
					...state.subcodeData,
					isFetching: true,
					hasError: false,
					error: subcodeReducerInitialState.subcodeData.error,
					data: subcodeReducerInitialState.subcodeData.data,
					subcodeId
				}
			};
		}

		case ACTION_TYPES.GET_SUBCODE_DATA_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				subcodeData: {
					...state.subcodeData,
					isFetching: false,
					hasError: false,
					data
				}
			};
		}

		case ACTION_TYPES.GET_SUBCODE_DATA_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				subcodeData: {
					...state.subcodeData,
					isFetching: false,
					hasError: true,
					data: subcodeReducerInitialState.subcodeData.data,
					subcodeId: subcodeReducerInitialState.subcodeData.subcodeId,
					error
				}
			};
		}

		case ACTION_TYPES.GET_FILE_SUBCODE_REQUEST: {
			return {
				...state,
				subcodeDataFile: {
					...state.subcodeDataFile,
					isFetching: true,
					hasError: false,
					error: subcodeReducerInitialState.subcodeDataFile.error,
					data: subcodeReducerInitialState.subcodeDataFile.data,
				}
			};
		}

		case ACTION_TYPES.GET_FILE_SUBCODE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				subcodeDataFile: {
					...state.subcodeDataFile,
					isFetching: false,
					hasError: false,
					error: subcodeReducerInitialState.subcodeDataFile.error,
					data
				}
			};
		}

		case ACTION_TYPES.GET_FILE_SUBCODE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				subcodeDataFile: {
					...state.subcodeDataFile,
					isFetching: false,
					hasError: true,
					data: subcodeReducerInitialState.subcodeDataFile.data,
					subcodeId: subcodeReducerInitialState.subcodeDataFile.subcodeId,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_SUBCODE_DATA: {
			return {
				...state,
				subcodeData: subcodeReducerInitialState.subcodeData
			};
		}
		// END Subcode data actions



		// Post subcode request actions
		case ACTION_TYPES.POST_SUBCODE_REQUEST: {
			const { data } = action.payload;
			return {
				...state,
				postSubcode: {
					...state.postSubcode,
					isFetching: true,
					hasError: false,
					data
				}
			};
		}
		case ACTION_TYPES.POST_SUBCODE_REQUEST_SUCCESS: {
			const { response } = action.payload;
			return {
				...state,
				postSubcode: {
					...state.postSubcode,
					isFetching: false,
					hasError: false,
					response
				}
			};
		}
		case ACTION_TYPES.POST_SUBCODE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postSubcode: {
					...state.postSubcode,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		// END Post subcode request actions


		// Subcode affectations actions
		case ACTION_TYPES.GET_SUBCODE_AFFECTATIONS_REQUEST: {
			const { subcodeId } = action.payload;
			return {
				...state,
				subcodeAffectations: {
					...state.subcodeAffectations,
					isFetching: true,
					hasError: false,
					error: subcodeReducerInitialState.subcodeAffectations.error,
					data: subcodeReducerInitialState.subcodeAffectations.data,
					subcodeId
				}
			};
		}
		case ACTION_TYPES.GET_SUBCODE_AFFECTATIONS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				subcodeAffectations: {
					...state.subcodeAffectations,
					isFetching: false,
					hasError: false,
					data
				}
			};
		}
		case ACTION_TYPES.GET_SUBCODE_AFFECTATIONS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				subcodeAffectations: {
					...state.subcodeAffectations,
					isFetching: false,
					hasError: true,
					data: subcodeReducerInitialState.subcodeAffectations.data,
					subcodeId: subcodeReducerInitialState.subcodeAffectations.subcodeId,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_SUBCODE_AFFECTATIONS: {
			return {
				...state,
				subcodeAffectations: {
					...subcodeReducerInitialState.subcodeAffectations
				}
			};
		}
		// END Subcode affectations actions

		// Subcode affectations history actions
		case ACTION_TYPES.GET_SUBCODE_AFFECTATIONS_HISTORY_REQUEST: {
			const { subcodeId } = action.payload;
			return {
				...state,
				subcodeAffectationsHistory: {
					...state.subcodeAffectationsHistory,
					isFetching: true,
					hasError: false,
					error: subcodeReducerInitialState.subcodeAffectationsHistory.error,
					data: subcodeReducerInitialState.subcodeAffectationsHistory.data,
					subcodeId
				}
			};
		}
		case ACTION_TYPES.GET_SUBCODE_AFFECTATIONS_HISTORY_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				subcodeAffectationsHistory: {
					...state.subcodeAffectationsHistory,
					isFetching: false,
					hasError: false,
					data
				}
			};
		}
		case ACTION_TYPES.GET_SUBCODE_AFFECTATIONS_HISTORY_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				subcodeAffectationsHistory: {
					...state.subcodeAffectationsHistory,
					isFetching: false,
					hasError: true,
					data: subcodeReducerInitialState.subcodeAffectationsHistory.data,
					subcodeId: subcodeReducerInitialState.subcodeAffectationsHistory.subcodeId,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_SUBCODE_AFFECTATIONS_HISTORY: {
			return {
				...state,
				subcodeAffectationsHistory: {
					...subcodeReducerInitialState.subcodeAffectationsHistory
				}
			};
		}
		// END Subcode affectations history actions

		// Post compensation request actions
		case ACTION_TYPES.POST_COMPENSATION_REQUEST: {

			const responseData = action.payload;

			return {
				...state,
				postCompensation: {
					...state.postCompensation,
					isFetching: true,
					hasError: false,
					responseData
				}
			};
		}
		case ACTION_TYPES.POST_COMPENSATION_REQUEST_SUCCESS: {
			const responseData = action.payload;
			return {
				...state,
				postCompensation: {
					...state.postCompensation,
					isFetching: false,
					hasError: false,
					responseData
				}
			};
		}
		case ACTION_TYPES.POST_COMPENSATION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postCompensation: {
					...state.postCompensation,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		// END Post compensation request actions

		//Preload
		case ACTION_TYPES.SET_PRELOAD_STATE: {
			
			const {preload} = action.payload;

			return {
				...state,
				preloadState: {
					...state.preloadState,
					data: preload
				}
			};
		}

		case ACTION_TYPES.CLEAR_PRELOAD_STATE: {
			return {
				...state,
				preloadState: {
					...subcodeReducerInitialState.preloadState
				}
			};
		}
		// END Preload

		default: {
			return state;
		}
	}
};

export const getSubcodeReducer = state => state.subcodeReducer;

export const getSubcodeProcessInProgress = state => getSubcodeReducer(state)?.subcodeProcessInProgress;

export const getSubcodeList = state => getSubcodeReducer(state)?.subcodeList;
export const getSubcodeListData = state => getSubcodeList(state)?.data;
export const getSubcodeListIsFetching = state => getSubcodeList(state)?.isFetching;

export const getSubcodeToEdit = state => getSubcodeReducer(state)?.subcodeToEdit;
export const getIsEditionMode = state => ( getSubcodeToEdit(state) !== subcodeReducerInitialState.subcodeToEdit);
export const getFormTitle = state => ( getIsEditionMode(state) ? 'Nueva modificación de crédito' : 'Nueva modificación de crédito' );

export const getSubcodeDetailsPageData = state => getSubcodeReducer(state)?.subcodeDetailsPageData;

export const getSelectedLegalInstrument = state => getSubcodeReducer(state)?.selectedLegalInstrument;

export const getSubcodeDetails = state => getSubcodeReducer(state)?.subcodeDetails;
export const getSubcodeDetailsData = state => getSubcodeDetails(state)?.data;
export const getSubcodeDetailsIsFetching = state => getSubcodeDetails(state)?.isFetching;
export const getSubcodeDetailsHasError = state => getSubcodeDetails(state)?.hasError;
export const getSubcodeDetailsError = state => getSubcodeDetails(state)?.error;

export const getSubcodeData = state => getSubcodeReducer(state)?.subcodeData;
export const getSubcodeDataData = state => getSubcodeData(state)?.data?.data?.[0];
export const getSubcodeDataIsFetching = state => getSubcodeData(state)?.isFetching;
export const getSubcodeDataHasError = state => getSubcodeData(state)?.hasError;
export const getSubcodeDataError = state => getSubcodeData(state)?.error;

export const getSubcodeDataFile = state => getSubcodeReducer(state)?.subcodeDataFile;
export const getSubcodeDataFileIsFetching = state => getSubcodeDataFile(state)?.isFetching;
export const getSubcodeDataFileHasError = state => getSubcodeDataFile(state)?.hasError;
export const getSubcodeDataFileError = state => getSubcodeDataFile(state)?.error;

export const getSubcodeAffectations = state => getSubcodeReducer(state)?.subcodeAffectations;
export const getSubcodeAffectationsData = state => getSubcodeAffectations(state)?.data;
export const getSubcodeAffectationsIsFetching = state => getSubcodeAffectations(state)?.isFetching;
export const getSubcodeAffectationsHasError = state => getSubcodeAffectations(state)?.hasError;
export const getSubcodeAffectationsError = state => getSubcodeAffectations(state)?.error;

export const getSubcodeAffectationsHistory = state => getSubcodeReducer(state)?.subcodeAffectationsHistory;
export const getSubcodeAffectationsHistoryData = state => getSubcodeAffectationsHistory(state)?.data;
export const getSubcodeAffectationsHistoryIsFetching = state => getSubcodeAffectationsHistory(state)?.isFetching;
export const getSubcodeAffectationsHistoryHasError = state => getSubcodeAffectationsHistory(state)?.hasError;
export const getSubcodeAffectationsHistoryError = state => getSubcodeAffectationsHistory(state)?.error;

export const getPostSubCodeData = state => getSubcodeReducer(state)?.postSubcode;
export const getPostSubCodeIsFetching = state => getPostSubCodeData(state)?.isFetching;

// New Compensation
export const getCompensationCreate = state => getSubcodeReducer(state)?.postCompensation?.responseData;
export const getCompensationCreateIsFetching = state => getCompensationCreate(state)?.isFetching;

// Preload
export const getPreloadState = state => getSubcodeReducer(state)?.preloadState;