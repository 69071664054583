import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Table, Button, Form, Row, Alert, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faExclamationTriangle, faFilter, faBroom, faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import { preventiveAccumulator, ultimateAccumulator, paidAccumulator } from 'src/utils/label';
import { formatterPeso } from 'src/utils/utils';
import { tryGetExpedientByFilter, tryAllExpedientByServiceRP } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import { getexpedientCreditByFilterData, getexpedientCreditByFilterIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';
import {clearRPConvertSelectedData, setRPConvertSelectedData, clearexpedientByFilterData} from 'src/redux/administrativeDocument/administrativeDocumentActions'
import ActionIcon from 'src/components/general/ActionIcon';
import RCPagination from 'src/components/common/RCPagination';
import ExpedientModalReportDetails from 'src/components/pages/Reports/ExpedientModalReportDetails';
import swal from 'sweetalert';

const ExpedientTable = ({filter, checkboxFlag, setCheckboxFlag, RPData, updateFilter}) => {
    const dispatch = useDispatch();
    const { handleSubmit, register } = useForm();
    const [completeLabel, setCompleteLabel] = useState('');
    const allowRPModule = filter?.availableRpByserviceId;

    //Modal
    const [dataModal, setDataModal] = useState();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const expedients = useSelector(state => getexpedientCreditByFilterData(state));
    const isFetching = useSelector(state => getexpedientCreditByFilterIsFetching(state));
    const totalItemsCount = expedients?.totalCount ? expedients?.totalCount : 1;
    const totalItems = expedients?.totalCount ? expedients?.totalCount : '0';
    const defaultFilter = {
        page: 1,
        pageSize: 10,
    };

    const messageAlert = allowRPModule ? "con Residuos Pasivos" : "asociados";

    const onPageChange = (pNumber) => {
        const params = {
            ...defaultFilter,
            filter: {
                ...filter,
                completeLabel
            },
            page: pNumber,
        }
        fetchList(params);
    };

    const fetchList = (params) => {
        dispatch(clearexpedientByFilterData());
        dispatch(tryGetExpedientByFilter(params));
    };

    const onClickModalCredit = (selectedExpedient) => {
        setDataModal(selectedExpedient);
		handleShow(true);
	};

    const cleanFilters = () => {
        setCompleteLabel('');
		fetchList({
            ...defaultFilter,
            filter: {
                ...filter
            }
        });
    };

    const handleCheckCondition = (expedient) => {
        return RPData?.some(obj => obj.id === expedient.id);
    };

    const handleItemsCheckBox = (expedient) =>{
        setCheckboxFlag(false);
        RPData?.some(obj => obj.id === expedient.id) ?
            dispatch(setRPConvertSelectedData(RPData.filter(obj => obj.id !== expedient.id))) :
            dispatch(setRPConvertSelectedData([...RPData, expedient]));
    };

    const handleSelectAllCheckBox = () =>{
        dispatch(clearRPConvertSelectedData());
        if(!checkboxFlag){
            const expedientAmount = expedients?.totalCount;
            swal({
                title: 'Confirmación.',
                text: `¿Está seguro de seleccionar todos los expedientes del servicio para convertirlos a Residuos Pasivos? Se seleccionarán un total de ${expedientAmount} Expedientes.`,
                icon: 'warning',
                buttons: ["Cancelar", "Aceptar"],
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        setCheckboxFlag(!checkboxFlag);
                        filter['includeRPTarget'] = false;
                        const params = {
                            ...defaultFilter,
                            filter,
                            pageSize: 5000,
                        };
                        dispatch(tryAllExpedientByServiceRP(params));
                    };
                });
        }else{
            filter['includeRPTarget'] = true;
            setCheckboxFlag(!checkboxFlag);
            fetchList({
                ...defaultFilter,
                filter: {
                    ...filter
                }
            });
        }
    };

    const onSubmitForm = (data) => {
        const noSpaceValue = completeLabel.replace(/ /g, "");
        setCompleteLabel(noSpaceValue);
        fetchList({
            ...defaultFilter,
            filter: {
                ...filter,
                completeLabel: noSpaceValue
            }
        });
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    useEffect(() => {
        fetchList({
            ...defaultFilter,
            filter: {
                ...filter
            }
        });
	}, [allowRPModule]);

    useEffect(() => {
        setCompleteLabel('');
    }, [updateFilter]);

    return (
        <div>
            <Form onSubmit={handleSubmit(onSubmitForm)} onKeyDown={handleKeyDown}>
                <Table bordered hover size='sm'>
                    <thead>
                        <tr>
                            <th className='text-center align-middle'>Expediente</th>
                            <th className='text-center align-middle'>{ preventiveAccumulator }</th>
                            <th className='text-center align-middle'>{ ultimateAccumulator }</th>
                            <th className='text-center align-middle'>Acumulador Obl. de pago</th>
                            <th className='text-center align-middle'>{ paidAccumulator }</th>
                            <th className='text-center align-middle'>Saldo a Convertir</th>
                            <th className={`text-center align-middle ${allowRPModule ? 'th-minwidth-exp-table': 'th-minwidth'}`} width ='8%'>Acciones</th>
                        </tr>
                        <tr className='secondary'>
                            <th className='text-center'>
                                <Form.Control
                                    className='text-center text-black-color'
                                    name='completeLabel'
                                    ref={register}
                                    size='sm'
                                    value={completeLabel}
                                    onChange={event => setCompleteLabel(event?.target?.value)}
                                />
                            </th>
                            <th className='text-center'></th>
                            <th className='text-center align-middle'></th>
                            <th className='text-center align-middle'></th>
                            <th className='text-center align-middle'></th>
                            <th className='text-center align-middle'></th>
                            <th className='text-center align-middle'>
                                <div className='d-flex justify-content-around'>
                                    {allowRPModule &&
                                        <ActionIcon
                                            size="lg"
                                            id="selectedAllExpedient"
                                            className="btn-primary search-button text-white-color"
                                            toolTipText="Seleccionar o deseleccionar todos los expedientes del servicio"
                                            icon={checkboxFlag ? faCheckSquare : faSquare}
                                            onClick={handleSelectAllCheckBox}
                                            disabled={false}
                                        />
                                    }
                                    <ActionIcon
                                        size="lg"
                                        id="search-button"
                                        className="btn-primary search-button text-white-color"
                                        toolTipText="Filtrar"
                                        icon={faFilter}
                                        // type='submit'
                                        onClick={() => onSubmitForm()}
                                    />
                                    <ActionIcon
                                        size="lg"
                                        id="clean-filter"
                                        className="btn-primary clean-filter text-white-color"
                                        toolTipText="Limpiar filtros"
                                        icon={faBroom}
                                        type='reset'
                                        onClick={()=> cleanFilters()}
                                    />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className='text-black-color'>
                        {expedients?.data?.length ? 
                            expedients?.data?.map((expedient) =>
                                <tr key={expedient.id}>
                                    <td className='text-center align-middle'>{expedient?.completeLabel}</td>
                                    <td className='text-right align-middle'>{formatterPeso.format(expedient?.total?.preventiveTotal)}</td>
                                    <td className='text-right align-middle'>{formatterPeso.format(expedient?.total?.definitiveTotal)}</td>
                                    <td className='text-right align-middle'>{formatterPeso.format(expedient?.total?.paymentObligationTotal)}</td>
                                    <td className='text-right align-middle'>{formatterPeso.format(expedient?.total?.paymentTotal)}</td>
                                    <td className='text-right align-middle'>{formatterPeso.format(expedient?.total?.passiveRemainingTotal)}</td>
                                    { allowRPModule &&
                                        <td className='d-flex justify-content-around align-middle'>
                                            <Form.Check
                                                value={expedient.id}
                                                checked={handleCheckCondition(expedient)}
                                                className ='text-center align-self-center expedient-table-check'
                                                name="selectedExpedient"
                                                onClick={()=>handleItemsCheckBox(expedient)}
                                            />
                                            <ActionIcon
                                                size='lg'
                                                id='details'
                                                toolTipText='Ver detalles'
                                                icon={faSearch}
                                                onClick={()=>onClickModalCredit(expedient)}
                                            />
                                        </td>
                                    }
                                </tr>
                            ) : !isFetching ?
                                <tr>
                                    <td colSpan={7}>
                                        <Row className='d-flex justify-content-center'>
                                            <Alert variant='info' className='d-flex w-50 justify-content-center mb-0 mx-5'>
                                                <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                                {`Sin expedientes ${messageAlert} para mostrar`}
                                            </Alert>
                                        </Row>
                                    </td>
                                </tr> :
                                <tr>
                                    <td colSpan={7}>
                                        <div className={`text-center text-black-color ${allowRPModule ? 'hidden' : ''}`}>
                                            <Spinner animation="border" size="sm" />
                                        </div>
                                    </td>
                                </tr>
                            
                        }
                    </tbody>
                </Table>
            </Form>
            {show &&
                <ExpedientModalReportDetails
                    show={show}
                    handleClose={handleClose}
                    dataModal={dataModal}
                    filtered={filter}
                />
            }
            <RCPagination
                activePage={expedients?.page}
                itemsCountPerPage={expedients?.perPage}
                totalItemsCount={totalItemsCount}
                pageRangeDisplayed={7}
                thePage={expedients?.page}
                totalItems={totalItems}
                innerClass="justify-content-center"
                onChange={onPageChange}
            /> 
        </div>
    );
};

export default ExpedientTable;